import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class ProveedorService {
  async getProveedoresAll(parametros) {
    const queryParams = parametros
      ? Object.keys(parametros)
          .map(
            (k) =>
              encodeURIComponent(k) + "=" + encodeURIComponent(parametros[k])
          )
          .join("&")
      : "";
    const proveedores = await fetchWrapper.get(
      `${ruta}/proveedor?` + queryParams
    );
    return proveedores;
  }
  async getProveedores() {
    return await fetchWrapper.get(`${ruta}/proveedor/todos`);
  }

  async sendProveedorNueva(proveedor) {
    const nuevaProveedor = await fetchWrapper.post(
      `${ruta}/proveedor`,
      proveedor
    );
    return nuevaProveedor;
  }
  async deleteProveedor(proveedor) {
    const proveedorEliminado = await fetchWrapper.delete(
      `${ruta}/proveedor/` + proveedor.id
    );
    return proveedorEliminado;
  }
  async updatedProveedor(proveedor) {
    const proveedorActualizado = await fetchWrapper.put(
      `${ruta}/proveedor/` + proveedor.id,
      proveedor
    );
    return proveedorActualizado;
  }
  async showProveedor(proveedor) {
    const proveedorShow = await fetchWrapper.get(
      `${ruta}/proveedor/` + proveedor
    );
    return proveedorShow;
  }

  async obtenerLaboratorios(proveedor_id) {
    return await fetchWrapper.get(
      `${ruta}/proveedor/` + proveedor_id + "/fabricas"
    );
  }
  async buscarXNombre(datos) {
    return await fetchWrapper.post(`${ruta}/proveedor/buscar`, datos);
  }

  async cargarPagoProveedores(datos) {
    return await fetchWrapper.post(`${ruta}/proveedor/pago`, datos);
  }
}
